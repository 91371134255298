/*
 * @Description: 
 * @Date: 2023-12-06 10:38:39
 * @LastEditTime: 2024-09-12 11:03:18
 */
import { Box, Card } from '@chakra-ui/react';
import './App.scss';
import Header from "./Header"
import Home from "./Home"
import Governance from "./Governance"
import Supported from "./Supported"
import City_Marquee from "./City_Marquee"
import Features from "./Features"
import Networking_System from "./Networking_System"
import Staking from "./Staking"
import Integrate_Decentralised from "./Integrate_Decentralised"
import BEST_REVIEWS from "./BEST_REVIEWS"
import Ready_supercharge from "./Ready_supercharge"
import Are_interested_AeroNyx from "./Are_interested_AeroNyx"
import { useEffect, useState } from 'react';
import axios from "axios"
import Can_i_help_you from "./Can_i_help_you"
import { Item1 } from "./3DImg/grid/Item1";
import HomeModel from "./Component/HomeModel"
import { View } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
const Wrapper = ({ name, children }: { name: string; children: any }) => {
  return (
    <View className="flex z-[2] bg-[#171717]  aspect-square  relative rounded-t-md">
      {children}
    </View>
  );
};


function App() {
  // const items = [
  //   { component: Item1, name: "Rings" },
  //   // { component: Item2, name: "Loop" },
  //   // { component: Item3, name: "Coins" },
  //   // { component: Item4, name: "Core" },
  //   // { component: Item5, name: "Rubik" },
  //   // { component: Item6, name: "Travel" },
  //   // { component: Item7, name: "Stagger" },
  //   // { component: Item8, name: "Balance" },
  //   // { component: Item9, name: "Pulse" },
  //   // { component: Item10, name: "Pie" },
  //   // { component: Item11, name: "Newton's Cradle" },
  //   // { component: Item12, name: "Arrows" },
  // ];
  let [data, setData] = useState<any>("")
  const [Language, setLanguage] = useState("English")









  useEffect(() => {
    axios
      .get(`https://aeronyx.network/api/v1/cms/init?language=${Language}`, {
        withCredentials: true,
      })
      .then((res: any) => {
        if (res.data.status == 200) {
          setData(res.data.data)
          createMeta(res.data.data)
        }

      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [Language])










  const createMeta = (data: any) => {
    document.title = data?.base?.title;
    const newMeta = document.createElement('meta');
    newMeta.name = 'description';
    newMeta.content = data?.base?.description;
    document.head.appendChild(newMeta);
  }



  return (
    <Box className="home" >
      {/* {items.map((item, index) => (
        <Wrapper key={index} name={item.name}>
          <Item1 />
        </Wrapper>
      ))} */}
      {/* <div className="min-h-screen text-white bg-[#0c0c0c] select-none background">
        <div className="container p-5 pb-20 mx-auto "   >
          <div className="relative mt-5 overflow-hidden">
            <div
              className="grid h-full gap-5 overflow-hidden group grid-clos-1 md:grid-cols-2 lg:grid-cols-4"
              data-gird
            >

            </div>

            <div className="fixed top-0 left-0 z-20 w-full h-screen pointer-events-none ">
              <Canvas
                camera={{
                  zoom: 0.8,
                }}
                className="fixed"
                eventSource={document.getElementById("root")!}
              >
                <View.Port />
              </Canvas>
            </div>
          </div>
        </div>
      </div> */}
      {/* <HomeModel /> */}
      <Header data={data} LanguageBack={(e: string) => setLanguage(e)} />
      <Home data={data} />
      <Governance data={data} />
      {/* <Supported /> */}
      {/* <City_Marquee /> */}
      <Features data={data} />
      <Networking_System data={data} />
      <Staking data={data} />
      <Integrate_Decentralised data={data} />
      {/* <BEST_REVIEWS /> */}
      <Ready_supercharge data={data} />
      <Are_interested_AeroNyx data={data} />
      <Can_i_help_you data={data}></Can_i_help_you>
    </Box>
  );
}

export default App;
